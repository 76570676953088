import { BigNumber } from '@ethersproject/bignumber'
import { Token, TokenAmount } from '@uniswap/sdk'
import { useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

// returns undefined if input token is undefined, or fails to get token contract,
// or contract total supply cannot be fetched
export function useBalanceOf(token?: Token, targetAddress?: string): TokenAmount | undefined {
  const contract = useTokenContract(token?.address, false)
  const balance: BigNumber = useSingleCallResult(contract, 'balanceOf', [targetAddress])?.result?.[0]

  return token && balance ? new TokenAmount(token, balance.toString()) : undefined
}
