export const migration: any = {
    token1 : { 
        address: "0xF741d30BdBE0241ee1b0b5c26A1689467484AE60",
        decimal: 18,
        name: "Memeone",
        symbol: "MONE"
    },
    token2: {
        address: "0x543665d195D18e7157E999D69637e5d25A82A4dA",
        decimal: 9,
        name: "MemeTwo",
        symbol: "MTWO"
    },
    migrationAdddress: "0x0856209ac576CC84083941F97dB00FE59CF941FD"
}