import { useMemo } from 'react'
import { SINGLE_STAKING_REWARDS_INFO } from '../constants/staking'
import { useActiveWeb3React } from '../hooks'

export default function useSingleStakingTokenAddresses(): string[] | undefined {
  const { chainId } = useActiveWeb3React()
  return useMemo(() => {
    const pools = chainId
      ? SINGLE_STAKING_REWARDS_INFO[chainId]?.map(stakingRewardInfo =>
          stakingRewardInfo.token.address
        )
      : []

    return pools
  }, [chainId])
}
