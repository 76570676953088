import React, { useCallback, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled from 'styled-components'
// import { Link } from 'react-router-dom'

import { JSBI} from '@uniswap/sdk'
import { RouteComponentProps } from 'react-router-dom'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useMasterBreederContract } from '../../hooks/useContract'
import { TYPE } from '../../theme'

import { RowBetween } from '../../components/Row'
import { CardSection, DataCard } from '../../components/earn/styled'
import { ButtonPrimary, ButtonWhite } from '../../components/Button'
import SingleStakingModal from '../../components/earn/SingleStakingModal'
import { useSingleStakingInfo } from '../../state/stake/hooks'
import SingleUnstakingModal from '../../components/earn/SingleUnstakingModal'
import SingleClaimRewardModal from '../../components/earn/SingleClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
// import { currencyId } from '../../utils/currencyId'
import { useBalanceOf } from '../../data/BalanceOf'
// import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
// import useUSDCPrice from '../../utils/useUSDCPrice'
import { BIG_INT_ZERO } from '../../constants'
import useGovernanceToken from '../../hooks/useGovernanceToken'

const PageWrapper = styled(AutoColumn)`
  padding: 20px;
  max-width: 680px;
  width: 100%;
  box-shadow: rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px;
  border-radius: 30px;
  border: 3px solid rgb(0, 113, 188);
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 680px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px 12px 0 0;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  background: #000;
  z-index: 2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`

const StyledBottomCard = styled(DataCard)<{ dim: any }>`
  background: ${({ theme }) => theme.bg6};
  opacity: ${({ dim }) => (dim ? 0.4 : 1)};
  margin-top: -40px;
  padding: 0 1.25rem 1rem 1.25rem;
  padding-top: 32px;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: none;
  border: 1px solid ${({ theme }) => theme.bg4};
  padding: 1rem;
  z-index: 1;
`

// const VoteCard = styled(DataCard)`
//   background: radial-gradient(76.02% 75.41% at 1.84% 0%, #27ae60 0%, #000000 100%);
//   overflow: hidden;
// `

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `};
`

export default function SingleStakingManage({
  match: {
    params: { currencyId }
  }
}: RouteComponentProps<{ currencyId: string}>) {
  const { account, chainId } = useActiveWeb3React()
  const masterBreederContract = useMasterBreederContract()
  const govToken = useGovernanceToken()

  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  const currency = useCurrency(currencyId)
  const tokenA = wrappedCurrency(currency ?? undefined, chainId)
  
  // const [, stakingTokenPair] = usePair(tokenA, tokenB)
  // console.log(tokenA, tokenB)
  const stakingInfo = useSingleStakingInfo(undefined, [currencyId])?.[0]

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  // const token = currencyA === ETHER ? tokenB : tokenA
  // const WETH = currencyA === ETHER ? tokenA : tokenB
  const token = tokenA
  const backgroundColor = useColor(token)

  // get WETH value of staked LP tokens
  const valueOfTotalStakedAmount = useBalanceOf(stakingInfo?.stakedAmount?.token, masterBreederContract?.address)
  // let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  // if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
  //   // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
  //   valueOfTotalStakedAmountInWETH = new TokenAmount(
  //     WETH,
  //     JSBI.divide(
  //       JSBI.multiply(
  //         JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
  //         JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
  //       ),
  //       totalSupplyOfStakingToken.raw
  //     )
  //   )
  // }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  // const USDPrice = useUSDCPrice(WETH)
  // const valueOfTotalStakedAmountInUSDC =
  //   valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  return (
    <>
      <PageWrapper gap="lg" justify="center">
        <RowBetween style={{ gap: '24px' }}>
          <TYPE.mediumHeader style={{ margin: 0 }}>
            {currency?.symbol} Staking
          </TYPE.mediumHeader>
          <DoubleCurrencyLogo currency0={currency ?? undefined} currency1={undefined} size={24} />
        </RowBetween>

        <DataRow style={{ gap: '24px' }}>
          <PoolData>
            <AutoColumn gap="sm">
              <TYPE.body style={{ margin: 0 }}>Total staked {currency?.symbol}</TYPE.body>
              <TYPE.body fontSize={24} fontWeight={500}>
                {valueOfTotalStakedAmount
                  ? `${valueOfTotalStakedAmount?.toSignificant(4, { groupSeparator: ',' })} ${currency?.symbol}`
                  : '-'}
              </TYPE.body>
            </AutoColumn>
          </PoolData>
          <PoolData>
            <AutoColumn gap="sm">
              <TYPE.body style={{ margin: 0 }}>Emission Rate</TYPE.body>
              <TYPE.body fontSize={24} fontWeight={500}>
              {stakingInfo
                  ? stakingInfo.active
                    ? `${stakingInfo.poolRewardsPerBlock.toSignificant(4, { groupSeparator: ',' })} 
                    ${govToken?.symbol} / block`
                    : `0 ${govToken?.symbol} / block`
                  : '-'}
              </TYPE.body>
            </AutoColumn>
          </PoolData>
        </DataRow>

        {/* {showAddLiquidityButton && (
          <VoteCard>
            
            <CardSection>
              <AutoColumn gap="md">
                <RowBetween>
                  <TYPE.white fontWeight={600}>Step 1. Get UNI-V2 Liquidity tokens</TYPE.white>
                </RowBetween>
                <RowBetween style={{ marginBottom: '1rem' }}>
                  <TYPE.white fontSize={14}>
                    {`UNI-V2 LP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`}
                  </TYPE.white>
                </RowBetween>
                <ButtonPrimary
                  padding="8px"
                  borderRadius="8px"
                  width={'fit-content'}
                  as={Link}
                  to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}
                >
                  {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`}
                </ButtonPrimary>
              </AutoColumn>
            </CardSection>
          </VoteCard>
        )} */}

        {stakingInfo && (
          <>
            <SingleStakingModal
              isOpen={showStakingModal}
              onDismiss={() => setShowStakingModal(false)}
              stakingInfo={stakingInfo}
              userLiquidityUnstaked={userLiquidityUnstaked}
            />
            <SingleUnstakingModal
              isOpen={showUnstakingModal}
              onDismiss={() => setShowUnstakingModal(false)}
              stakingInfo={stakingInfo}
            />
            <SingleClaimRewardModal
              isOpen={showClaimRewardModal}
              onDismiss={() => setShowClaimRewardModal(false)}
              stakingInfo={stakingInfo}
            />
          </>
        )}

        <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
          <BottomSection gap="lg" justify="center">
            <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
              <CardSection>
                
                <AutoColumn gap="md">
                  <RowBetween>
                    <TYPE.white fontWeight={600}>Your Staked {currency?.symbol}</TYPE.white>
                  </RowBetween>
                  <RowBetween style={{ alignItems: 'baseline' }}>
                    <TYPE.white fontSize={36} fontWeight={600}>
                      {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                    </TYPE.white>
                    <TYPE.white>
                      {currency?.symbol}
                    </TYPE.white>
                  </RowBetween>
                </AutoColumn>
              </CardSection>
            </StyledDataCard>
            <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
              
              <AutoColumn gap="sm">
                <RowBetween>
                  <div>
                    <TYPE.black>Your unclaimed TREATS</TYPE.black>
                  </div>
                  {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                    <ButtonWhite
                      padding="8px"
                      borderRadius="8px"
                      width="fit-content"
                      onClick={() => setShowClaimRewardModal(true)}
                    >
                      Claim
                    </ButtonWhite>
                  )}
                </RowBetween>
                <RowBetween style={{ alignItems: 'baseline' }}>
                  <TYPE.largeHeader fontSize={36} fontWeight={600}>
                    <CountUp
                      key={countUpAmount}
                      isCounting
                      decimalPlaces={4}
                      start={parseFloat(countUpAmountPrevious)}
                      end={parseFloat(countUpAmount)}
                      thousandsSeparator={','}
                      duration={1}
                    />
                  </TYPE.largeHeader>
                  <TYPE.black fontSize={16} fontWeight={500}>
                    <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px ' }}>
                      ⚡
                    </span>
                    {/* {stakingInfo?.active
                      ? stakingInfo?.rewardRate
                          ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                          ?.toSignificant(4, { groupSeparator: ',' }) ?? '-'
                      : '0'}
                    {' UNI / week'} */}
                  </TYPE.black>
                </RowBetween>
              </AutoColumn>
            </StyledBottomCard>
          </BottomSection>
          <TYPE.main style={{ textAlign: 'center' }} fontSize={14}>
            <span role="img" aria-label="wizard-icon" style={{ marginRight: '8px' }}>
              ⭐️
            </span>
            When you withdraw, the contract will automagically claim SATORUFi on your behalf!
          </TYPE.main>

          {!showAddLiquidityButton && (
            <DataRow style={{ marginBottom: '1rem' }}>
              {stakingInfo && stakingInfo.active && (
                <ButtonPrimary padding="8px" borderRadius="8px" width="160px" onClick={handleDepositClick}>
                  {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) ? 'Deposit' : `Deposit ${currency?.symbol}`}
                </ButtonPrimary>
              )}

              {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
                <>
                  <ButtonPrimary
                    padding="8px"
                    borderRadius="8px"
                    width="160px"
                    onClick={() => setShowUnstakingModal(true)}
                  >
                    Withdraw
                  </ButtonPrimary>
                </>
              )}
            </DataRow>
          )}
          {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : !stakingInfo?.active ? null : (
            <TYPE.main>{userLiquidityUnstaked.toSignificant(6)} {currency?.symbol} available</TYPE.main>
          )}
        </PositionInfo>
      </PageWrapper>
      
    </>
    
  )
}
