import { useMemo } from 'react'
import { ChainId } from '@uniswap/sdk'
import { SINGLE_STAKING_REWARDS_INFO, SingleStakingRewardsInfo } from '../constants/staking'

export default function useFilterSingleStakingRewardsInfo(
  chainId: ChainId | undefined,
  active: boolean | undefined = undefined,
  pairToFilterBy?: (string | null)[]
): SingleStakingRewardsInfo[] {
  return useMemo(() => {
    const pools = chainId
      ? SINGLE_STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
          pairToFilterBy === undefined
            ? true
            : pairToFilterBy === null
            ? false
            : pairToFilterBy.includes(stakingRewardInfo.token.address)
        ) ?? []
      : []

    if (active !== undefined) {
      return pools?.filter(stakingRewardInfo => stakingRewardInfo.active === active) ?? []
    }

    return pools
  }, [chainId, active, pairToFilterBy])
}
