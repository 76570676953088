import { WETH } from '@uniswap/sdk'
import { useMemo } from 'react'
import useSingleAssetHokkToken from './useSingleAssetHokkToken'
import useTokenWethPrice from './useTokenWETHPrice'
// import getToken from '../utils/getToken'
import { useActiveWeb3React } from './index'

export default function useSingleAssetTokensWithWETHPrices(): Record<string, any> {
  const { chainId } = useActiveWeb3React()
  // const blockchain = chainId

  const weth = chainId && WETH[chainId]

  const hokk = useSingleAssetHokkToken()
  const hokkWETHPrice = useTokenWethPrice(hokk)
  console.log('govTokenWETHPrice?.raw', hokkWETHPrice?.raw.numerator.toString())
  console.log('govTokenWETHPrice?.raw', hokkWETHPrice?.raw.denominator.toString())
  // const BUSDTicker = chainId !== ChainId.ROPSTEN ? 'BUSD' : 'BUSD'
  // const BUSD: Token | undefined = getToken(chainId, BUSDTicker)
  // const BUSDWETHPrice = useTokenWethPrice(BUSD)

  // const USDCTicker = chainId === ChainId.ROPSTEN ? 'USDC' : 'USDC'
  // const USDC: Token | undefined = getToken(chainId, USDCTicker)
  // const USDCWETHPrice = useTokenWethPrice(USDC)

  // // Harmony specific tokens
  // const bscBUSD: Token | undefined = chainId === ChainId.ROPSTEN ? getToken(chainId, 'bscBUSD') : undefined
  // const bscBUSDWETHPrice = useTokenWethPrice(bscBUSD)

  // const bridgedETH: Token | undefined = ChainId.ROPSTEN ? getToken(chainId, 'ETH') : undefined
  // const bridgedETHWETHPrice = useTokenWethPrice(bridgedETH)

  return useMemo(() => {
    return {
      WETH: { token: weth, price: undefined },
      hokk: {token: hokk, price: hokkWETHPrice}
      // BUSD: { token: BUSD, price: BUSDWETHPrice },
      // USDC: { token: USDC, price: USDCWETHPrice },
      // bscBUSD: { token: bscBUSD, price: bscBUSDWETHPrice },
      // bridgedETH: { token: bridgedETH, price: bridgedETHWETHPrice }
    }
  }, [
    // chainId,
    // blockchain,
    weth,
    hokk,
    hokkWETHPrice,
    // BUSD,
    // BUSDWETHPrice,
    // USDC,
    // USDCWETHPrice,
    // bscBUSD,
    // bscBUSDWETHPrice,
    // bridgedETH,
    // bridgedETHWETHPrice
  ])
}
