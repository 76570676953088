import { CurrencyAmount, JSBI, Token, Trade } from '@uniswap/sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
//@ts-ignore
//import TradingViewWidget, { Themes } from "react-tradingview-widget";
import axios from 'axios';
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink, { DefaultVersionLink } from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'
import SwapHeader from '../../components/swap/SwapHeader'
import Logo from '../../assets/images/logomain.png'
import LogoDark from '../../assets/images/logomain.png'

import { useDarkModeManager } from '../../state/user/hooks'

import { INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { getTradeVersion } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useToggledVersion, { DEFAULT_VERSION, Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
    useDefaultsFromURLSearch,
    useDerivedSwapInfo,
    useSwapActionHandlers,
    useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance, useUserSingleHopOnly } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { ClickableText } from '../Pool/styleds'
import Loader from '../../components/Loader'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { isTradeBetter } from 'utils/trades'
import { RouteComponentProps } from 'react-router-dom'

const SwapCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden auto;
  z-index: 1;

  width: 30%;

  flex: 1 0;

  @media (max-width: 768px) {
    width: 100%;
  }

`

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  
`

// const ChartWrapper = styled.div`
//   max-width: 900px;
//   width: 70%;
//   margin: 0 20px 30px 20px;
//   border: 3px solid #0071bc;
//   min-height: 300px;

//   @media (max-width: 768px) {
//     margin: 0 0px 30px 0px;
//     width: 100%;
//   }
// `

const GasStationsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 900px;
  width: 100%;
  padding: 30px;
`

const GasInfoWrapper = styled.div`
  display: flex;
  padding: 0 15px 30px 15px;
  align-items: center;
  justify-content: center;
`

const GasAmoundWrapper = styled.div`
  line-height: 50px;
  font-weight: 600;
  display: inline-block;
  font-size: 40px;
  padding-right: 10px;
  border-right: 1px solid #797979;
`

const GasWaitWrapper = styled.div`
  padding-left: 10px;
  font-size: 18px;
  text-transform: uppercase;
  color: ${props => props.color || "#ffffff"};
`

const GasTitle = styled.div`
  font-size: 20px;
  color: ${props => props.color || "#ffffff"};
  margin-top: 30px;
`

export default function Swap({ history }: RouteComponentProps) {
    const loadedUrlParams = useDefaultsFromURLSearch()

    const [darkMode] = useDarkModeManager()

    // token warning stuff
    const [loadedInputCurrency, loadedOutputCurrency] = [
        useCurrency(loadedUrlParams?.inputCurrencyId),
        useCurrency(loadedUrlParams?.outputCurrencyId)
    ]
    const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
    const urlLoadedTokens: Token[] = useMemo(
        () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
        [loadedInputCurrency, loadedOutputCurrency]
    )
    const handleConfirmTokenWarning = useCallback(() => {
        setDismissTokenWarning(true)
    }, [])

    // dismiss warning if all imported tokens are in active lists
    const defaultTokens = useAllTokens()
    const importTokensNotInDefault =
        urlLoadedTokens &&
        urlLoadedTokens.filter((token: Token) => {
            return !Boolean(token.address in defaultTokens)
        })

    const { account } = useActiveWeb3React()
    const theme = useContext(ThemeContext)

    // toggle wallet when disconnected
    const toggleWalletModal = useWalletModalToggle()

    // for expert mode
    const toggleSettings = useToggleSettingsMenu()
    const [isExpertMode] = useExpertModeManager()

    // get custom setting values for user
    const [allowedSlippage] = useUserSlippageTolerance()

    // swap state
    const { independentField, typedValue, recipient } = useSwapState()
    const {
        v1Trade,
        v2Trade,
        currencyBalances,
        parsedAmount,
        currencies,
        inputError: swapInputError
    } = useDerivedSwapInfo()

    const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
        currencies[Field.INPUT],
        currencies[Field.OUTPUT],
        typedValue
    )
    const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
    const { address: recipientAddress } = useENSAddress(recipient)
    const toggledVersion = useToggledVersion()
    const tradesByVersion = {
        [Version.v1]: v1Trade,
        [Version.v2]: v2Trade
    }
    const trade = showWrap ? undefined : tradesByVersion[toggledVersion]
    const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION]

    const betterTradeLinkV2: Version | undefined =
        toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade) ? Version.v2 : undefined

    const parsedAmounts = showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount
        }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
        }

    const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
    const isValid = !swapInputError
    const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

    const handleTypeInput = useCallback(
        (value: string) => {
            onUserInput(Field.INPUT, value)
        },
        [onUserInput]
    )
    const handleTypeOutput = useCallback(
        (value: string) => {
            onUserInput(Field.OUTPUT, value)
        },
        [onUserInput]
    )

    // reset if they close warning without tokens in params
    const handleDismissTokenWarning = useCallback(() => {
        setDismissTokenWarning(true)
        history.push('/swap/')
    }, [history])

    // modal and loading
    const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
        showConfirm: boolean
        tradeToConfirm: Trade | undefined
        attemptingTxn: boolean
        swapErrorMessage: string | undefined
        txHash: string | undefined
    }>({
        showConfirm: false,
        tradeToConfirm: undefined,
        attemptingTxn: false,
        swapErrorMessage: undefined,
        txHash: undefined
    })

    const formattedAmounts = {
        [independentField]: typedValue,
        [dependentField]: showWrap
            ? parsedAmounts[independentField]?.toExact() ?? ''
            : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
    }

    const route = trade?.route
    const userHasSpecifiedInputOutput = Boolean(
        currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
    )
    const noRoute = !route

    // check whether the user has approved the router on the input token
    const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

    // check if user has gone through approval process, used to show two step buttons, reset on token change
    const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
    const [gasInfo, setGasInfo] = useState<any>({
        fastest: 0,
        fastestWait: 0,
        fast: 0,
        fastWait: 0,
        safeLow: 0,
        safeLowWait: 0,
    })

    // mark when a user has submitted an approval, reset onTokenSelection for input field
    useEffect(() => {
        if (approval === ApprovalState.PENDING) {
            setApprovalSubmitted(true)
        }
    }, [approval, approvalSubmitted])

    useEffect(() => {
        const getEtherGasInfo = async () => {
            const { data } = await axios.get<any>('https://ethgasstation.info/json/ethgasAPI.json')
            setGasInfo({
                fastest: data.fastest / 10,
                fast: data.fast / 10,
                safeLow: data.safeLow / 10,
                fastestWait: data.fastestWait,
                fastWait: data.fastWait,
                safeLowWait: data.safeLowWait,
            })
        }
        getEtherGasInfo();
    }, []);

    const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
    const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

    // the callback to execute the swap
    const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

    const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

    const [singleHopOnly] = useUserSingleHopOnly()

    const handleSwap = useCallback(() => {
        if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
            return
        }
        if (!swapCallback) {
            return
        }
        setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
        swapCallback()
            .then(hash => {
                setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

                ReactGA.event({
                    category: 'Swap',
                    action:
                        recipient === null
                            ? 'Swap w/o Send'
                            : (recipientAddress ?? recipient) === account
                                ? 'Swap w/o Send + recipient'
                                : 'Swap w/ Send',
                    label: [
                        trade?.inputAmount?.currency?.symbol,
                        trade?.outputAmount?.currency?.symbol,
                        getTradeVersion(trade)
                    ].join('/')
                })

                ReactGA.event({
                    category: 'Routing',
                    action: singleHopOnly ? 'Swap with multihop disabled' : 'Swap with multihop enabled'
                })
            })
            .catch(error => {
                setSwapState({
                    attemptingTxn: false,
                    tradeToConfirm,
                    showConfirm,
                    swapErrorMessage: error.message,
                    txHash: undefined
                })
            })
    }, [
        priceImpactWithoutFee,
        swapCallback,
        tradeToConfirm,
        showConfirm,
        recipient,
        recipientAddress,
        account,
        trade,
        singleHopOnly
    ])

    // errors
    const [showInverted, setShowInverted] = useState<boolean>(false)

    // warnings on slippage
    const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

    // show approve flow when: no error on inputs, not approved or pending, or approved in current session
    // never show if price impact is above threshold in non expert mode
    const showApproveFlow =
        !swapInputError &&
        (approval === ApprovalState.NOT_APPROVED ||
            approval === ApprovalState.PENDING ||
            (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
        !(priceImpactSeverity > 3 && !isExpertMode)

    const handleConfirmDismiss = useCallback(() => {
        setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
        // if there was a tx hash, we want to clear the input
        if (txHash) {
            onUserInput(Field.INPUT, '')
        }
    }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

    const handleAcceptChanges = useCallback(() => {
        setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
    }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

    const handleInputSelect = useCallback(
        inputCurrency => {
            setApprovalSubmitted(false) // reset 2 step UI for approvals
            onCurrencySelection(Field.INPUT, inputCurrency)
        },
        [onCurrencySelection]
    )

    const handleMaxInput = useCallback(() => {
        maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
    }, [maxAmountInput, onUserInput])

    const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
        onCurrencySelection
    ])


    const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

    // default to ETHHOKK for now
    // const chartSymbol = currencies?.INPUT && currencies?.OUTPUT ?
    //   //@ts-ignore
    //   (currencies?.INPUT.symbol + currencies?.OUTPUT.symbol === 'ETHHOKK' ?
    //   //@ts-ignore
    //     'WETHHOKK' : currencies?.INPUT.symbol + currencies?.OUTPUT.symbol)
    //   : "WETHHOKK"

    return (
        <>
            <TokenWarningModal
                isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
                tokens={importTokensNotInDefault}
                onConfirm={handleConfirmTokenWarning}
                onDismiss={handleDismissTokenWarning}
            />

            <SwapPoolTabs active={'swap'} />
            <img width={'235px'} style={{ marginBottom: '15px', marginTop: '-40px' }} src={darkMode ? LogoDark : Logo} alt="logo" />
            <MainWrapper>

                <SwapCardWrapper>
                    <AppBody>
                        <SwapHeader />
                        <Wrapper id="swap-page">
                            <ConfirmSwapModal
                                isOpen={showConfirm}
                                trade={trade}
                                originalTrade={tradeToConfirm}
                                onAcceptChanges={handleAcceptChanges}
                                attemptingTxn={attemptingTxn}
                                txHash={txHash}
                                recipient={recipient}
                                allowedSlippage={allowedSlippage}
                                onConfirm={handleSwap}
                                swapErrorMessage={swapErrorMessage}
                                onDismiss={handleConfirmDismiss}
                            />

                            <AutoColumn gap={'md'}>
                                <CurrencyInputPanel
                                    label={independentField === Field.OUTPUT && !showWrap && trade ? 'From (estimated)' : 'From'}
                                    value={formattedAmounts[Field.INPUT]}
                                    showMaxButton={!atMaxAmountInput}
                                    currency={currencies[Field.INPUT]}
                                    onUserInput={handleTypeInput}
                                    onMax={handleMaxInput}
                                    onCurrencySelect={handleInputSelect}
                                    otherCurrency={currencies[Field.OUTPUT]}
                                    id="swap-currency-input"
                                />
                                <AutoColumn justify="space-between">
                                    <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                                        <ArrowWrapper clickable>
                                            <ArrowDown
                                                size="16"
                                                onClick={() => {
                                                    setApprovalSubmitted(false) // reset 2 step UI for approvals
                                                    onSwitchTokens()
                                                }}
                                                color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.text2}
                                            />
                                        </ArrowWrapper>
                                        {recipient === null && !showWrap && isExpertMode ? (
                                            <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                                                + Add a send (optional)
                                            </LinkStyledButton>
                                        ) : null}
                                    </AutoRow>
                                </AutoColumn>
                                <CurrencyInputPanel
                                    value={formattedAmounts[Field.OUTPUT]}
                                    onUserInput={handleTypeOutput}
                                    label={independentField === Field.INPUT && !showWrap && trade ? 'To (estimated)' : 'To'}
                                    showMaxButton={false}
                                    currency={currencies[Field.OUTPUT]}
                                    onCurrencySelect={handleOutputSelect}
                                    otherCurrency={currencies[Field.INPUT]}
                                    id="swap-currency-output"
                                />

                                {recipient !== null && !showWrap ? (
                                    <>
                                        <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                                            <ArrowWrapper clickable={false}>
                                                <ArrowDown size="16" color={theme.text2} />
                                            </ArrowWrapper>
                                            <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                                                - Remove send
                                            </LinkStyledButton>
                                        </AutoRow>
                                        <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                                    </>
                                ) : null}

                                {showWrap ? null : (
                                    <Card padding={showWrap ? '.25rem 1rem 0 1rem' : '0px'} borderRadius={'20px'}>
                                        <AutoColumn gap="8px" style={{ padding: '0 16px' }}>
                                            {Boolean(trade) && (
                                                <RowBetween align="center">
                                                    <Text fontWeight={500} fontSize={14} color={theme.text2}>
                                                        Price
                                                    </Text>
                                                    <TradePrice
                                                        price={trade?.executionPrice}
                                                        showInverted={showInverted}
                                                        setShowInverted={setShowInverted}
                                                    />
                                                </RowBetween>
                                            )}
                                            {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                                                <RowBetween align="center">
                                                    <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                                                        Slippage Tolerance
                                                    </ClickableText>
                                                    <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                                                        {allowedSlippage / 100}%
                                                    </ClickableText>
                                                </RowBetween>
                                            )}
                                        </AutoColumn>
                                    </Card>
                                )}
                            </AutoColumn>
                            <BottomGrouping>
                                {swapIsUnsupported ? (
                                    <ButtonPrimary disabled={true}>
                                        <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
                                    </ButtonPrimary>
                                ) : !account ? (
                                    <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
                                ) : showWrap ? (
                                    <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                                        {wrapInputError ??
                                            (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                                    </ButtonPrimary>
                                ) : noRoute && userHasSpecifiedInputOutput ? (
                                    <GreyCard style={{ textAlign: 'center' }}>
                                        <TYPE.main mb="4px">Insufficient liquidity for this trade.</TYPE.main>
                                        {singleHopOnly && <TYPE.main mb="4px">Try enabling multi-hop trades.</TYPE.main>}
                                    </GreyCard>
                                ) : showApproveFlow ? (
                                    <RowBetween>
                                        <ButtonConfirmed
                                            onClick={approveCallback}
                                            disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                                            width="48%"
                                            altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                                            confirmed={approval === ApprovalState.APPROVED}
                                        >
                                            {approval === ApprovalState.PENDING ? (
                                                <AutoRow gap="6px" justify="center">
                                                    Approving <Loader stroke="white" />
                                                </AutoRow>
                                            ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                                                'Approved'
                                            ) : (
                                                'Approve ' + currencies[Field.INPUT]?.symbol
                                            )}
                                        </ButtonConfirmed>
                                        <ButtonError
                                            onClick={() => {
                                                if (isExpertMode) {
                                                    handleSwap()
                                                } else {
                                                    setSwapState({
                                                        tradeToConfirm: trade,
                                                        attemptingTxn: false,
                                                        swapErrorMessage: undefined,
                                                        showConfirm: true,
                                                        txHash: undefined
                                                    })
                                                }
                                            }}
                                            width="48%"
                                            id="swap-button"
                                            disabled={
                                                !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                                            }
                                            error={isValid && priceImpactSeverity > 2}
                                        >
                                            <Text fontSize={16} fontWeight={500}>
                                                {priceImpactSeverity > 3 && !isExpertMode
                                                    ? `Price Impact High`
                                                    : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                                            </Text>
                                        </ButtonError>
                                    </RowBetween>
                                ) : (
                                    <ButtonError
                                        onClick={() => {
                                            if (isExpertMode) {
                                                handleSwap()
                                            } else {
                                                setSwapState({
                                                    tradeToConfirm: trade,
                                                    attemptingTxn: false,
                                                    swapErrorMessage: undefined,
                                                    showConfirm: true,
                                                    txHash: undefined
                                                })
                                            }
                                        }}
                                        id="swap-button"
                                        disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                                        error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                                    >
                                        <Text fontSize={20} fontWeight={500}>
                                            {swapInputError
                                                ? swapInputError
                                                : priceImpactSeverity > 3 && !isExpertMode
                                                    ? `Price Impact Too High`
                                                    : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                                        </Text>
                                    </ButtonError>
                                )}
                                {showApproveFlow && (
                                    <Column style={{ marginTop: '1rem' }}>
                                        <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
                                    </Column>
                                )}
                                {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                                {betterTradeLinkV2 && !swapIsUnsupported && toggledVersion === Version.v1 ? (
                                    <BetterTradeLink version={betterTradeLinkV2} />
                                ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
                                    <DefaultVersionLink />
                                ) : null}
                            </BottomGrouping>
                        </Wrapper>
                    </AppBody>
                    {!swapIsUnsupported ? (
                        <AdvancedSwapDetailsDropdown trade={trade} />
                    ) : (
                        <UnsupportedCurrencyFooter show={swapIsUnsupported} currencies={[currencies.INPUT, currencies.OUTPUT]} />
                    )}
                </SwapCardWrapper>
            </MainWrapper>
            <GasTitle color={darkMode ? "#fffff" : "#9fa5ad"}>Recommended Gas Prices in Gwei</GasTitle>
            <GasStationsWrapper>
                <GasInfoWrapper>
                    <GasAmoundWrapper style={{ color: '#FFF' }}>{gasInfo.safeLow}</GasAmoundWrapper>
                    <GasWaitWrapper color={darkMode ? "#fffff" : "#9fa5ad"}>
                        <span style={{ opacity: 0.9 }}>STANDARD</span>
                        <span style={{ opacity: 0.8, marginLeft: '5px' }}>&lt; {gasInfo.safeLowWait}m</span>
                    </GasWaitWrapper>
                </GasInfoWrapper>
                <GasInfoWrapper>
                    <GasAmoundWrapper style={{ color: '#FFF' }}>{gasInfo.fast}</GasAmoundWrapper>
                    <GasWaitWrapper color={darkMode ? "#fffff" : "#9fa5ad"}>
                        <span style={{ opacity: 0.9 }}>FAST</span>
                        <span style={{ opacity: 0.8, marginLeft: '5px' }}>&lt; {gasInfo.fastWait}m</span>
                    </GasWaitWrapper>
                </GasInfoWrapper>
                <GasInfoWrapper>
                    <GasAmoundWrapper style={{ color: '#FFF' }}>{gasInfo.fastest}</GasAmoundWrapper>
                    <GasWaitWrapper color={darkMode ? "#fffff" : "#9fa5ad"}>
                        <span style={{ opacity: 0.9 }}>TRADER</span>
                        <span style={{ opacity: 0.8, marginLeft: '5px' }}>&lt; {gasInfo.fastestWait}m</span>
                    </GasWaitWrapper>
                </GasInfoWrapper>
            </GasStationsWrapper>
        </>
    )
}
