import { TokenAmount } from '@uniswap/sdk'
import { BlueCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Column'
// import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { SwapPoolTabs } from 'components/NavigationTabs'
import { RowBetween } from 'components/Row'
import MigrationHeader from 'components/swap/MigrationHeader'
import { Wrapper } from 'components/swap/styleds'
// import { useCurrency } from 'hooks/Tokens'
import AppBody from 'pages/AppBody'
// import React, { useCallback, useState } from 'react'
import React, { useEffect, useState } from 'react'
import { SingleStakingInfo } from 'state/stake/hooks'
import styled from 'styled-components'
import { TYPE } from 'theme'
import { darken } from 'polished'
// import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { migration } from '../../utils/migration-config';
import erc20 from '../../constants/abis/ERC20Token.json'
import migartionhelperabi from '../../constants/abis/migartionhelper.json'
import { useActiveWeb3React } from '../../hooks'
import { ethers } from 'ethers';
import { getTokenLogoURL } from '../../components/CurrencyLogo'
// import { ButtonError } from 'components/Button'


// import { Input as NumericalInput } from '../NumericalInput'
// import { Input as NumericalInput } from '../../components/NumericalInput'


// import { maxAmountSpend } from 'utils/maxAmountSpend'



const MainWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
  
`
// const Currency = styled.div`
//     position: relative;
//     border-radius: 10px;
//     background-color: ${({ theme }) => theme.bg2};
//     z-index: 1;
//     padding: 20px;
// `
const SwapCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden auto;
  z-index: 1;

  width: 30%;

  flex: 1 0;

  @media (max-width: 768px) {
    width: 100%;
  }

`
const InputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.bg2};
  z-index: 1;
`
const Container = styled.div`
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.panelBorder};
  background-color: ${({ theme }) => theme.bg1};
`
const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`
const InputRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
`
const NumericalInput = styled.input`
    width: 0;
    position: relative;
    font-weight: 500;
    outline: none;
    border: none;
    flex: 1 1 auto;
    background-color: ${({ theme }) => theme.bg1};
    font-size: 24px;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
    color: white;
    border-radius: 20px;
    -webkit-appearance: textfield;

    ::-webkit-search-decoration {
    -webkit-appearance: none;
    }

    [type='number'] {
    -moz-appearance: textfield;
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

::placeholder {
  color: ${({ theme }) => theme.text4};
}
`
const StyledBalanceMax = styled.button`
  height: 28px;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0.5rem;
  font-size: 0.875rem;

  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin-right: 0.5rem;
  `};
`
// const CurrencySelect = styled.button`
//   align-items: center;
//   height: 1.6rem;
//   font-size: 20px;
//   font-weight: 500;
//   background-color: theme.primary1;
//   color: theme.white;
//   border-radius: 50%;
//   box-shadow:  '0px 6px 10px rgba(0, 0, 0, 0.075)';
//   outline: none;
//   cursor: pointer;
//   user-select: none;
//   border: none;
//   padding: 14px;
//   // margin-right: 10px;

//   :focus,
//   :hover {
//     background-color:  darken(0.05, theme.primary1);
//   }
// `
const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
// const StyledTokenName = styled.span`
//   margin: 0 0.25rem 0 0.75rem;
//   font-size: 16px;
//   padding-right: 10px;

// `
const CurrencyName = styled.span`
    font-size: 15px;
    margin-right: 10px;
    margin-left: 10px;
`


export const ButtonGray = styled.button`
  background-color: ${({ theme }) => theme.bg4};
  color: ${({ theme }) => theme.text1};
  font-size: 15px;
  font-weight: bold;
  padding: 20px 50px 20px 50px;
  border-radius: 20px;
  border-color: ${({ theme }) => theme.bg4};
  margin-right: 15px;
  cursor: pointer;
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg4)};
  }
`
export const Buttondisabled = styled.button`
  background-color: ${({ theme }) => theme.bg5};
  color: ${({ theme }) => theme.text1};
  font-size: 15px;
  font-weight: bold;
  padding: 20px 50px 20px 50px;
  border-radius: 20px;
  border-color: ${({ theme }) => theme.bg5};
  margin-right: 10px;
  cursor: nit-allowed;
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg5)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg5)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg5)};
  }
`

interface StakingModalProps {
  stakingInfo: SingleStakingInfo
  userLiquidityUnstaked: TokenAmount | undefined
}


export default function Migration({ stakingInfo, userLiquidityUnstaked }: StakingModalProps) {
  const { library, account } = useActiveWeb3React();
  const [oldTokenBalance, setOldTokenBalance] = useState(0.0);
  const [oldTokenAmount, setOldTokenAmount] = useState(0.0);
  const [needApprove, setNeedApprove] = useState(false);
  const [approveTxt, setApproveTxt] = useState("Approve");
  const [migrateTxt, setMigrateTxt] = useState("Migrate");
  const [oldTokenDecimals, setOldTokenDecimals] = useState(0.0);
  const [divider, setdivider] = useState("1");
  const maxINT = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

  useEffect(() => {
    console.log("call");
    const call = async () => {
      try {
        const oldToken = new ethers.Contract(migration.token1.address, erc20, library?.getSigner(account?.toString()));
        const decimals = await oldToken.decimals();
        const allowance = await oldToken.allowance(account?.toString(), migration.migrationAdddress);
        setNeedApprove(maxINT !== allowance.toString());
        setOldTokenDecimals(decimals)
        const bal = parseFloat(ethers.utils.formatUnits(await oldToken.balanceOf(account), decimals));
        setOldTokenBalance(bal);
        const migrationContract = new ethers.Contract(migration.migrationAdddress, migartionhelperabi, library?.getSigner(account?.toString()));
        const divider = (await migrationContract.divider()).toString();
        setdivider(divider);
      } catch (err) {
        // alert(err);
        // setERR(err.message);
      }
    }
    call();
  }, [library, account])

  const maxClick = () => {
    setOldTokenAmount(oldTokenBalance);
  }

  const migrate = async () => {
    if (migrateTxt !== "Migrate") return;
    setMigrateTxt("Migrating...");
    try {

      const oldToken = new ethers.Contract(migration.migrationAdddress, migartionhelperabi, library?.getSigner(account?.toString()));
      const bal = ethers.utils.parseUnits(`${oldTokenAmount}`, oldTokenDecimals);
      console.log(bal.toString());
      const tx = await oldToken.claim(bal);
      await tx.wait();
      setOldTokenBalance(oldTokenBalance - oldTokenAmount);
      setMigrateTxt("Migrated");
    } catch (err) {
      setMigrateTxt("Migrate");
      // setERR(err.message);
    }
  }

  const approve = async () => {
    setApproveTxt("Approving...");
    try {

      const oldToken = new ethers.Contract(migration.token1.address, erc20, library?.getSigner(account?.toString()));
      const tx = await oldToken.approve(migration.migrationAdddress, maxINT);
      await tx.wait();
      setApproveTxt("Approved");
      setNeedApprove(false);
    } catch (err) {
      setApproveTxt("Approve");
      // setERR(err.message);
    }
  }




    return (
        <>
            <SwapPoolTabs active={'migration'} />
            <MainWrapper>
                <SwapCardWrapper>
                    <AppBody>
                        <MigrationHeader />
                        <Wrapper>
                            <RowBetween mb="2rem">
                                <ColumnCenter>
                                    <BlueCard>
                                        <AutoColumn gap="5px">
                                            <TYPE.link fontWeight={400} color={'primaryText1'}>
                                                <b>Migration Ratio: {`${1} ${migration.token1.symbol} = ${1/parseInt(divider)} ${migration.token2.symbol}`}</b>
                                            </TYPE.link>
                                        </AutoColumn>
                                    </BlueCard>
                                </ColumnCenter>
                            </RowBetween>
                            {/* <AutoColumn gap="10px" className="m-2" > */}
                            {/* <Currency> */}
                            <InputPanel>
                                <Container>
                                    <LabelRow>
                                        <RowBetween>
                                            <TYPE.body fontWeight={500} fontSize={14}>
                                                {/* abc */}
                                            </TYPE.body>
                                            <TYPE.body
                                                fontWeight={400}
                                                fontSize={16}
                                                mb="2"
                                                style={{ display: 'inline', cursor: 'pointer' }}
                                            >
                                                Available to migrate: {oldTokenBalance}
                                            </TYPE.body>
                                        </RowBetween>
                                    </LabelRow>
                                    <InputRow style={{ padding: '0', borderRadius: '8px' }} >
                                        <NumericalInput value={oldTokenAmount} onChange={(e) => {
                                            let val = parseFloat(e.target.value);
                                            val = isNaN(val) ? 0.0 : val;
                                            setOldTokenAmount(val);
                                        }} />
                                        <StyledBalanceMax onClick={maxClick}>MAX</StyledBalanceMax>
                                        <img alt="logo" style={{ height: 25, width: 25 }} src={getTokenLogoURL(migration.token1.address)}></img>
                                       
                                         

                                        <CurrencyName>
                                            {`${migration.token1.symbol}`}
                                        </CurrencyName>

                                    </InputRow>

                                </Container>
                            </InputPanel>
                            <RowBetween m="1rem ">
                            <Aligner>
                  {!needApprove||oldTokenAmount ===0 ?
                    <Buttondisabled disabled={!needApprove||oldTokenAmount === 0} onClick={approve}>
                      {approveTxt}
                    </Buttondisabled> :
                    <ButtonGray onClick={approve}>
                      {approveTxt}
                    </ButtonGray>
                  }
                </Aligner>
                <Aligner>
                  {needApprove||oldTokenAmount === 0 ?
                    <Buttondisabled disabled={needApprove||oldTokenAmount === 0} onClick={migrate}>
                      {migrateTxt}
                    </Buttondisabled> :
                    <ButtonGray onClick={migrate}>
                      {migrateTxt}
                    </ButtonGray>
                  }
                </Aligner>


                            </RowBetween>
                            {/* </Currency> */}
                            {/* </AutoColumn> */}

                        </Wrapper>
                    </AppBody>
                </SwapCardWrapper>
            </MainWrapper>
        </>
    )
}
