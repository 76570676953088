import {
  ChainId,
  Token
} from '@uniswap/sdk'
import getPairTokensWithDefaults from '../utils/getPairTokensWithDefaults'
import { ZERO_ADDRESS } from '../constants/index'

export interface StakingRewardsInfo {
  pid: number
  tokens: [Token, Token]
  active: boolean
}

export interface SingleStakingRewardsInfo {
  pid: number
  token: Token
  active: boolean
}

export const STAKING_REWARDS_INFO: {
  [chainId in ChainId] ? : StakingRewardsInfo[]
} = {
  [ChainId.MAINNET]: [
    {
      pid: 0,
      tokens: getPairTokensWithDefaults(ChainId.ROPSTEN, 'SATO/WETH'),
      active: true
    },
    {
      pid: 1,
      tokens: getPairTokensWithDefaults(ChainId.ROPSTEN, 'SATORUFi/WETH'),
      active: true
    }
  ],
  [ChainId.ROPSTEN]: [
    {
    pid: 0,
    tokens: getPairTokensWithDefaults(ChainId.ROPSTEN, 'SATO/WETH'),
    active: true
  },
  {
    pid: 1,
    tokens: getPairTokensWithDefaults(ChainId.ROPSTEN, 'SATORUFi/WETH'),
    active: true
  },
  {
    pid: 2,
    tokens: getPairTokensWithDefaults(ChainId.ROPSTEN, 'SAITAMA/WETH'),
    active: true
  },
  {
    pid: 3,
    tokens: getPairTokensWithDefaults(ChainId.ROPSTEN, 'FLOKI/WETH'),
    active: true
  },
  {
    pid: 4,
    tokens: getPairTokensWithDefaults(ChainId.ROPSTEN, 'SHIB/WETH'),
    active: true
  }
 ]
}

export const SINGLE_STAKING_REWARDS_INFO: {
  [chainId in ChainId] ? : SingleStakingRewardsInfo[]
} = {
  [ChainId.MAINNET]: [
    {
      pid: 2,
      token: new Token(ChainId.MAINNET, ZERO_ADDRESS, 9, 'HOKK', 'HOKK'),
      active: true
    },
    {
      pid: 3,
      token: new Token(ChainId.MAINNET, ZERO_ADDRESS, 18, 'TREATS', 'TREATS'),
      active: true
    },
  ],
  [ChainId.ROPSTEN]: [
    // {
    //   pid: 2,
    //   token: new Token(ChainId.ROPSTEN, "0x8C22aa1af3ec03Fe987036a35D6543A6905544B6", 18, 'TREATS', 'TREATS'),
    //   active: true
    // },
    // {
    //   pid: 3,
    //   token: new Token(ChainId.ROPSTEN, "0xecCC05B5998A416E7AD8562176d4C62aB50f2c27", 9, 'HOKK', 'HOKK'),
    //   active: true
    // },
    {
      pid: 2,
      token: new Token(ChainId.ROPSTEN, "0x08F61B59E86b2A13010259C24170bF11B42711A9", 18, 'SATORUFi', 'SATORUFi'),
      active: true
    },
    {
      pid: 3,
      token: new Token(ChainId.ROPSTEN, "0xa6bf90e97ac25f3211789fccb45e7ab5aff784a0", 9, 'SATO', 'SATO'),
      active: true
    },
 ]
}
